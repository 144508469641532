.auth-container-root {
  display: flex;
  justify-content: space-around;
  overflow-x: hidden;
}
.auth-container-root > .auth-container-carousel {
}
.auth-container-root > div {
  width: 50%;
}
.auth-container-root > .auth-container-carousel img {
  max-width: 250px !important;
  position: relative;
  display: block;
  left: -40px;
  height: 450px;
  margin: 40px 0px;
}

.auth-container-root > .auth-container-form {
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-container-root > .auth-container-form .login {
  width: 80%;
}

.auth-container-form button {
  text-transform: uppercase;
}
@media only screen and (max-width: 800px) {
  .auth-container-root .auth-container-carousel {
    display: none;
    visibility: hidden;
  }
  .auth-container-form {
    margin-top: 220px;
    margin-bottom: 120px;
    width: 100% !important;
  }
  .auth-container-form .login {
    margin-top: 0px !important;
    width: 90% !important;
  }
}
