.App {
  text-align: center;
  min-width: 100vw;
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  font-family: "Ubuntu", sans-serif;
}

body,
html {
  overflow-x: hidden !important;
}

.slider-dots {
  display: none !important;
}

.amz-button {
  /*margin-top: 20px;*/
  text-decoration: none;
  font-size: 18px;
  display: block;
  max-width: 200px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #eba249;
  color: #000;
  margin: 0 auto;
}

.topbar {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
}
.topbar .logo {
  float: left;
  padding: 0 10px;
  margin-top: 15px;
  margin-left: 20px;
}
.topbar .logo img {
  max-width: 200px;
}
.topbar .topmenu {
  text-align: right;
  padding: 0 10px;
  text-align: right;
  display: inline-block;
}
.topbar .topmenu .product-link-sm {
  display: none;
}
.topbar .topmenu li {
  display: inline-block;
  list-style-type: none;
  padding: 20px 10px;
}
.topbar .topmenu li a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  padding: 8px;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border-radius: 0px;
  transition: all 0.2s ease-in-out;
}
.topbar .topmenu li a:hover {
  background-color: #fff;
  color: #000;
  border-radius: 15px;
}

.header {
  position: fixed;
  background: url("/images/background.png"), #292121;
  animation: slide 20s linear infinite;
}

.header:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 975px) {
  .header ul {
    overflow: hidden;
  }
  .dropdown {
    display: none !important;
  }
  .product-link-sm {
    display: block !important;
  }
  .topbar .logo {
    margin-top: 10px;
    margin-left: 10px;
  }
  .topbar .topmenu {
    text-align: left;
    display: inline-block;
    background-color: #292121;
    width: 95%;
  }
  .topbar .topmenu li {
    display: inline-block;
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  .topbar .topmenu li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    display: block;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  .topbar .topmenu li a:hover {
    background-color: #fff;
    color: #000;
    border-radius: 15px;
  }
}
.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown-menu a {
  color: #333 !important;
}
.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}
/* menu */
.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  margin-top: 0px;
}
/* menu icon */
.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .nav-item.active:after {
  width: 100%;
  height: 4px;
  color: white;
}
.header .menu-icon .navicon {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}
.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.header .menu-icon .navicon:before {
  top: 5px;
}
.header .menu-icon .navicon:after {
  top: -5px;
}
/* menu btn */
.header .menu-btn {
  display: none;
}
.header .menu-btn:checked ~ .menu {
  max-height: 500px;
  width: 100%;
}
.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}
/* 48em = 768px */
@media (min-width: 975px) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
.footer-cover {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: #000;
}
.footer-cover .footer {
  max-width: 980px;
  height: auto;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: absolute !important;
}
.footer-cover .footer .footer-content {
  padding: 30px;
}
.footer-cover .footer .footer-content h1 {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
}
.footer-cover .footer .footer-content p {
  color: #999;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}
.footer-cover .footer .footer-content ul li {
  display: inline-block;
  list-style-type: none;
  padding: 10px 10px;
}
.footer-cover .footer .footer-content ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.footer-cover .footer .footer-content ul li a:hover {
  background-color: #fff;
  color: #000;
  border-radius: 15px;
}

#slideshow > div {
  position: absolute;

  right: 100px;
  bottom: 0;
}

#slideshow > div > img {
  width: 100%;
  height: auto;
  max-width: 550px;
  max-height: auto;
  border-radius: 20px;
}

.dummy-height {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: #333;
  min-height: 80px;
  position: relative;
  z-index: 99;
}
.dummy-box {
  width: 100%;
  height: 100px;
  background-color: #fff;
}
.sec-1-cover {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.sec-1-cover .sec-1 {
  height: 80vh;
  display: table;
}
.sec-1-cover .sec-1 .content {
  width: 100%;
  height: auto;
  max-width: 700px;
  display: table-cell;
  vertical-align: middle;
  z-index: 9999999;
  position: relative;
}
.sec-1-cover .sec-1 .content p {
  margin-bottom: 0;
}
.sec-1-cover .sec-1 .content h1 {
  font-size: 34px;
  margin-top: 20px;
}
.sec-1-cover .sec-1 .content h3 {
  font-size: 24px;
  margin-top: 10px;
}
.sec-1-cover .sec-1 .content a {
  margin-top: 20px;
  text-decoration: none;
  font-size: 18px;
  display: block;
  max-width: 200px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #eba249;
  color: #000;
}
/* .sec-1-cover .sec-1 .img {
   width: 100%;
   height: auto;
   max-width: 500px;
   display: table-cell;
   vertical-align: middle;
}
 .sec-1-cover .sec-1 .img img {
   max-width: 300px;
   height: auto;
}*/
.box-cover {
  max-width: 960px;
  width: 100%;
  clear: both;
  height: auto;
  margin: 100px auto;
}
.box-cover h2 {
  font-size: 24px;
  color: #000;
  text-align: center;
}
.box-cover .card {
  text-align: center;
  margin: 10px auto;
}
.box-cover .card img {
  max-width: 160px;
  max-height: 200px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.box-cover .col-sm-4:nth-child(4) .card img {
  max-width: 200px !important;
}
.box-cover .card a {
}

.box-cover .box {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  box-shadow: 5px 5px 15px #888;
  max-width: 22%;
  min-height: 200px;
  display: table;
  width: 100%;
  float: left;
  border-radius: 5px;
  margin: 10px;
  text-align: center;
}
.box-cover .box div {
  width: 100%;
  height: auto;
  display: table-cell;
  vertical-align: middle;
}
.box-cover .box h3 {
  font-size: 18px;
}
.sec-2 {
  height: auto;
  background-color: #f6f6f6;
  width: 100%;
  margin: 100px auto;
  text-align: center;
  padding: 50px 10px 50px 10px;
}
.sec-2 .about-2-in {
  max-width: 992px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.sec-2 h2 {
  color: #333;
  font-size: 24px;
}

.sec-2 p {
  max-width: 600px;
  height: auto;
  margin: 0 auto 30px auto;
  color: #333;
  font-size: 18px;
  padding-bottom: 40px;
}

/*slideshow styles*/
/*slide show styles*/
#slideshow {
  margin: 0 auto;
  /*position: relative; */
  width: 100%;
  /*max-width: 300px;*/
  /*max-height: 500px;*/
  /*padding: 1% 1% 56.25% 1%; 56.25 is for 16x9 resolution*/
  border-radius: 20px;
  /*background: rgba(0,0,0,0);*/
  /*box-shadow: 0 0 20px rgba(0,0,0,0);*/
  /*box-sizing:border-box;*/
}

#slideshow:hover i,
#slideshow:hover .slider-dots {
  opacity: 1;
}

.slidebtn {
  z-index: 99;
  background: transparent;
  outline: none;
  border: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  padding: 0 10px 0 10px;
}

.slidebtn:active,
.slidedtn:focus {
  outline: none;
}

.slidebtn i {
  color: #fff;
  font-size: 72px;
  opacity: 0.2;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.prev {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
}

.next {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
}

.slider-dots {
  opacity: 0.2;
  list-style: none;
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  left: 50%;
  bottom: 3%;
  transform: translate(-50%, 0);
  z-index: 99;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.slider-dots li {
  color: #000;
  display: inline;
  font-size: 48px;
  margin-right: 5px;
  cursor: pointer;
}

.slider-dots li.active-dot {
  color: #fff;
}

/*
JQUERY SLIDER BY JohnRostislavovich - https://codepen.io/JohnRostislavovich
ALL YOU HAVE TO DO:
-copy the whole code html, css & js
-include jquery lib
-change the images
*/

/*footer styles*/

.page-footer {
  bottom: 0;
}

/* STYLES SPECIFIC TO FOOTER  */
.footer {
  width: 100%;
  position: relative;
  height: auto;
  background-color: #292121;
}
.footer .col {
  width: 190px;
  height: auto;
  float: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0px 20px 20px 20px;
}
.footer .col h1 {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 17px;
  padding: 20px 0px 5px 0px;
  color: rgba(255, 255, 255, 0.2);
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}
.footer .col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer .col ul li {
  color: #ffffff;
  font-size: 14px;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
}
.footer .col ul li a {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
}
.social ul li {
  display: inline-block;
  padding-right: 5px !important;
}
.social ul li a {
  font-size: 20px !important;
  padding: 0px 10px;
  text-decoration: none;
}

.footer .col ul li a:hover {
  color: #999999;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
}
.clearfix {
  clear: both;
}
@media only screen and (min-width: 1280px) {
  .contain {
    width: 1200px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1139px) {
  .contain .social {
    width: 1000px;
    display: block;
  }
  .social h1 {
    margin: 0px;
  }
}
@media only screen and (max-width: 950px) {
  .footer .col {
    width: 33%;
  }
  .footer .col h1 {
    font-size: 14px;
  }
  .footer .col ul li {
    font-size: 13px;
  }
}
@media only screen and (max-width: 500px) {
  .footer .col {
    width: 50%;
  }
  .footer .col h1 {
    font-size: 14px;
  }
  .footer .col ul li {
    font-size: 13px;
  }
  .sec-1-cover .sec-1 .content h1 {
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
  }
  .sec-1-cover .sec-1 .content h3 {
    font-size: 18px;
    margin-top: 10px;
    /*padding-bottom: 20px;*/
  }
}
@media only screen and (max-width: 340px) {
  .footer .col {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .sec-1-cover {
    width: 100%;
    max-width: 1024px;
    height: auto;
    margin: 0 auto;
  }
  .sec-1-cover .sec-1 {
    height: 75vh;
    display: block;
    position: relative;
  }
  .sec-1-cover .sec-1 .content {
    width: 100%;
    height: auto;
    max-width: 768px;
    display: block;
    position: absolute;
    z-index: 9999;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    color: #000;
    bottom: 0;
    text-align: center;
    padding: 20px 10px;
  }

  .sec-1-cover .sec-1 .content h1 {
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
  }

  #slideshow > div {
    top: -10%;
    right: 0;
  }
  #slideshow img {
    margin-top: 10px;
    max-width: auto;
    max-height: 60vh;
  }

  #slideshow > div > img {
    width: 75%;
    height: auto;
    max-width: 600px;
    max-height: auto;
    border-radius: 20px;
  }

  .sec-1-cover .sec-1 .content h3 {
    font-size: 18px;
    margin-top: 10px;
    /*padding-bottom: 20px;*/
  }
  .sec-1-cover .sec-1 .content a {
    /*margin-top: 20px;*/
    font-size: 18px;
    display: block;
    max-width: 180px;
    text-align: center;
    padding: 10px 0;
    border-radius: 5px;
    background-color: #eba249;
    color: #000;
    position: fixed;
    bottom: 15px;
    left: 0;
    right: 0;
    z-index: 99999;
    width: 100%;
    height: auto;
    margin: 20px auto 10px auto;
  }
  .sec-1-cover .sec-1 .img {
    width: 100%;
    height: auto;
    max-width: 560px;
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .box-cover {
    max-width: 960px;
    width: 100%;
    clear: both;
    height: auto;
    margin: 100px auto;
  }
  .box-cover .box {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    box-shadow: 5px 5px 15px #888;
    max-width: 50%;
    min-height: 200px;
    display: table;
    width: 100%;
    float: none;
    border-radius: 5px;
    margin: 10px auto;
    text-align: center;
  }
  .box-cover .box div {
    width: 100%;
    height: auto;
    display: table-cell;
    vertical-align: middle;
  }
  .box-cover .box h3 {
    font-size: 18px;
  }
  .sec-2 {
    height: auto;
    background-color: #f6f6f6;
    width: 100%;
    margin: 100px auto;
    text-align: center;
    padding: 50px 10px 50px 10px;
  }
  .sec-2 h2 {
    color: #333;
    font-size: 26px;
  }
  .sec-2 p {
    max-width: 600px;
    height: auto;
    margin: 0 auto 30px auto;
    font-size: 18px;
    color: #333;
  }
}

/* Index Component Styles */
.sec-1-cover {
  background-color: #292121;
  width: 100%;
  height: 100vh;
}
.sec-1-cover .sec-1 {
  width: 100%;
  height: 100%;
  background-image: url("https://drive.google.com/uc?export=view&id=1xaN85Tnq8ZSAriEb4ca55-jOTBB6ahc2");
  -webkit-animation: slide 20s linear infinite;
}
.sec-1-cover .slide-cover {
  max-width: 1440px;
  height: 100vh;
  margin: 0 auto;
  width: 98%;
}
.sec-1-cover .slide-cover .slides .carousel {
  overflow: hidden;
}
.sec-1-cover .slide-cover .slides .carousel .slide .slide-content {
  float: left;
  position: absolute;
  top: 35%;
  padding-left: 80px;
  z-index: 9;
}
.sec-1-cover .slide-cover .slides .carousel .slide .slide-content h1 {
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  font-size: 48px;
  padding: 10px 0;
}
.sec-1-cover .slide-cover .slides .carousel .slide .slide-content h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  color: #fff;
  padding: 10px 0;
}
.sec-1-cover .slide-cover .slides .carousel .slide .slide-content a {
  font-family: "Open Sans", sans-serif;
  color: #000;
  border-radius: 15px;
  font-size: 18px;
  position: relative;
  top: 40px;
  background-color: #fff;
  padding: 20px;
  text-decoration: none;
}
.sec-1-cover .slide-cover .slides .carousel .slide .slide-img {
  float: right;
  max-width: 280px;
  height: auto;
  position: relative;
  right: 16%;
  margin-top: 10%;
}
.carousel-indicators {
  position: absolute !important;
}
.sec-2-cover {
  width: 100%;
  height: auto;
  text-align: center;
}
.sec-2-cover h2 {
  padding-top: 100px;
  font-family: "Open Sans", sans-serif;
  font-size: 33px;
}
.sec-2-cover p {
  max-width: 900px;
  font-size: 24px;
  height: auto;
  padding: 20px 0 100px 0;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
}
.sec-3-cover {
  width: 100%;
  height: auto;
  background-color: #292121;
}
.sec-3-cover .sec-3 {
  max-width: 1440px;
  height: auto;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  background-image: url("https://drive.google.com/uc?export=view&id=1KqCotQHOji2OaMy53X4dZRV3PoOx2oUu");
}
.sec-3-cover .sec-3 h2 {
  padding-top: 100px;
  font-family: "Open Sans", sans-serif;
  font-size: 33px;
}
.sec-3-cover .sec-3 p {
  color: #fff;
  max-width: 900px;
  font-size: 24px;
  height: auto;
  padding: 20px 0 30px 0;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
}
.sec-3-cover .sec-3 button {
  margin: 10px 0px 40px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  padding: 10px 15px;
  text-decoration: none;
  clear: both;
}
.sec-4-cover {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.sec-4-cover .sec-4 {
  max-width: 960px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.sec-4-cover .sec-4 .service-list {
  width: 96%;
  height: auto;
  margin: 0 auto;
}
.sec-4-cover .sec-4 .service-list ul {
  width: 100%;
  height: auto;
  margin: 100px auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 41px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 41px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 41px -7px rgba(0, 0, 0, 0.75);
  clear: both;
}
.sec-4-cover .sec-4 .service-list ul li {
  display: inline-block;
  text-align: center;
  width: 24%;
  height: auto;
}
.sec-4-cover .sec-4 .service-list ul li img {
  padding: 30px 0 10px 0;
}
.sec-4-cover .sec-4 .service-list ul li h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
}
.sec-4-cover .sec-4 .service-list ul li p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -400px -200px;
  }
}
/* SEeAllProducts Css */

/* Carousel  */

.carousel-caption {
  padding-left: 80px;
  float: left;
  position: absolute;
  top: 35%;
  z-index: 9;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.carousel-caption h1 {
  font-size: 48px;
  padding: 10px 0 !important;
  font-family: "Ubuntu", sans-serif;
  color: white;
  text-align: left;
  font-weight: bold;
}
.carousel-caption h2 {
  font-size: 26px;
  padding: 10px 0;
  font-family: "Open Sans", sans-serif;
  color: white;
  text-align: left;
}

.carousel-caption button {
  font-family: "Open Sans", sans-serif;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  top: 30px;
  padding: 10px 15px;
  font-weight: bold;
}
.carousel-item {
  float: left;
}

.carousel-item > div:nth-child(1) > div:nth-child(1) {
  float: right !important;
  max-width: 300px !important;
  right: 16%;
  margin-top: 10%;
  height: auto;
  position: relative;
}

@media only screen and (max-width: 800px) {
  .height-400 {
    height: 400px;
    overflow: hidden;
  }
  .sec-1-cover,
  .carousel,
  .carousel-inner,
  .carousel-item,
  .sec-1,
  .slide-cover,
  .slides {
    height: 100% !important;
  }
  .carousel-caption {
    top: 20%;
    padding-left: 30px;
  }
  .carousel-caption h1 {
    font-size: 30px;
  }

  .carousel-caption h2 {
    font-size: 25px;
    width: 350px;
  }
  .carousel-caption button {
    font-size: 15px;
    padding: 8px;
    top: 10px;
  }
  .carousel-item > div:nth-child(1) > div:nth-child(1) {
    height: 280px;
    right: 10%;
    margin-top: 13%;
  }
  .carousel-item > div:nth-child(1) img {
    height: 300px;
    width: auto;
  }
}
@media only screen and (max-width: 714px) {
  .carousel-item > div:nth-child(1) img {
    height: 250px;
    margin-top: 20%;
    width: auto;
  }
}

@media only screen and (max-width: 600px) {
  .height-400 {
    height: 320px;
    overflow: hidden;
  }

  .carousel-caption {
    top: 20%;
    padding-left: 30px;
  }
  .carousel-caption h1 {
    font-size: 20px;
  }

  .carousel-caption h2 {
    font-size: 15px;
    width: 250px;
  }
  .carousel-caption button {
    font-size: 12px;
    padding: 8px;
    top: 10px;
  }
  .carousel-item > div:nth-child(1) > div:nth-child(1) {
    margin-top: 80px;
    height: 200px;
  }
  .carousel-item > div:nth-child(1) img {
    height: 200px;
    width: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px !important;
  color: inherit !important;
}

.carousel-item > div:nth-child(1) img {
  max-width: 300px;
  float: right;
  position: relative;
  display: block;
}

/* Card */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 30%;
  display: inline-grid;
  margin: 30px 10px;
  padding: 5px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  border: 0;
  font-weight: 400;
  flex-direction: column;
  min-width: 0;
  position: relative;
  background-color: #fff;
  background-clip: border-box;
  background: url("/images/background.png"), #fff;
  height: 600px;
}

.card button {
  width: 95%;
  margin: auto;
}
.card img {
  max-width: 200px;
  max-height: 208px;
  height: auto;
  margin: 10px auto 0;
  width: auto;
  vertical-align: middle;
  border-style: none;
}
.card a:hover {
  text-decoration: none !important;
}
.card h1 {
  font-size: 22px;
  font-weight: 300px;
  margin-top: 10px;
  padding: 10px;
  color: black !important;
  text-transform: uppercase;
}
.card .price {
  color: grey;
  font-size: 22px;
  margin-bottom: 0px;
}
.card .product-disc {
  /* max-height: 150px; */
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0px 10px;
  text-align: justify;
  margin-bottom: 10px;
}
.card .product-btn {
  text-decoration: none;
  border: none;
  outline: 0;
  display: block;
  padding: 10px 10px;
  color: white;
  margin: 10px auto;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 90%;
  font-size: 16px;
}

.card .product-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

@media only screen and (max-width: 950px) {
  .card {
    width: 40%;
  }
}

@media only screen and (max-width: 720px) {
  .card {
    width: 90%;
  }
}

/* Our products */
.common-head-bg {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: #292121;
  text-align: center;
}
.common-head-content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-image: url(/assets/images/animated-bg.png);
  -webkit-animation: slide 20s linear infinite;
}

.common-head-content h1 {
  color: #fff !important ;
  padding-top: 100px;
  font-size: 26px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

.common-head-content p {
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 100px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}
.products-cover {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

/* About */
.about {
  width: 100%;
  height: auto;
  margin: 100px auto;
}

.about p {
  max-width: 980px;
  height: auto;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 35px;
}

/* Contact */
.contact-cover {
  width: 100%;
  height: auto;
}
.contact {
  max-width: 1024px;
  height: auto;
  width: 100%;
  margin: 30px auto;
}

.left-content,
.right-content {
  width: 50%;
  height: auto;
  float: left;
}

.left-content .address {
  max-width: 300px;
  width: 100%;
  height: auto;
  margin: 0 auto 0 auto;
}
.left-content .address h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  margin: 100px auto 10px auto;
  text-align: left;
}
.left-content ul li {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #000;
  list-style-type: none;
  padding: 10px 0;
  text-align: left;
}

.left-content a {
  color: black;
  font-weight: 500;
}

.right-content .contact-form {
  max-width: 400px;
  width: 100%;
  padding: 10px;
  height: auto;
  margin: 0 auto;
}

.contact-form {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  margin: 30px 0 10px 0;
}
.contact-form h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  margin: 30px 0 10px 0;
  margin-bottom: 10px !important;
  text-align: left;
}
.contact-form input,
.contact-form textarea,
.contact-form button {
  width: 100%;
  height: auto;
  font-size: 16px;
  padding: 10px 15px;
  border: 1px solid #000;
  margin: 0px 0;
  border-radius: 4px;
}

.contact-form button {
  background-color: black;
  color: white;
}
.form-group.col-md-12 {
  padding: 0px;
}
.form.was-validated .form-group.col-md-12 {
  margin-bottom: 0px !important;
}
.contact-form .invalid-feedback {
  font-size: 14px;
  margin: 0px;
  min-height: 1rem;
}

@media only screen and (max-width: 880px) {
  .left-content,
  .right-content {
    width: 100%;
  }
  .contact-form {
    margin: auto !important;
  }
}

/* Login */
.login {
  width: 80%;
  margin-top: 220px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px !important;
  position: relative;
}

.login .button {
  width: 100%;
}

.no-margin-top {
  margin-top: 0px !important;
}
.login-header {
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
}
.auth-container-root {
  background: url("/images/background.png");
  animation: slide 20s linear infinite;
}

.auth-container-root .carousel-indicators li {
  background-color: black;
}

.auth-container-root .carousel img {
  left: -50px !important;
}

.login-user-icon {
  width: 80px;
  height: 80px;
  border: 3px solid black;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -40px;
  background: white;
  left: 40%;
}

/* .signup-link { */
/* }
.signup-link:after {
  content: "";
  width: 100%;
  left: 0px;
  z-index: -2;
  top: 10px;
  position: absolute;
  background: black;
  height: 1px;
} */

@media only screen and (min-width: 720px) {
  .login {
    width: 50%;
    margin-top: 15%;
  }
}
@media only screen and (min-width: 880px) {
  .login {
    width: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .sec-1-cover .slide-cover .slides .carousel {
    overflow: hidden;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-content {
    padding-left: 30px;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-content h1 {
    font-size: 36px;
    padding: 10px 0;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-content h2 {
    font-size: 18px;
    padding: 10px 0;
    line-height: 10px;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-content a {
    font-size: 16px;
    top: 30px;
    padding: 10px;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-img {
    float: right;
    max-width: 300px;
    right: 0;
    margin-top: 10%;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-img img {
    max-width: 300px;
  }
  .sec-2-cover h2 {
    padding-top: 70px;
  }
  .sec-2-cover p {
    font-size: 18px;
    padding: 20px 10px 100px 10px;
  }
  .sec-3-cover .sec-3 h2 {
    padding-top: 100px;
    font-family: "Open Sans", sans-serif;
    font-size: 33px;
  }
  .sec-3-cover .sec-3 p {
    font-size: 18px;
    padding: 20px 10px 30px 10px;
  }
  .sec-3-cover .sec-3 a {
    margin: 10px 0px 40px 0;
    font-size: 14px;
    padding: 5px 10px;
  }
  .sec-4-cover .sec-4 .service-list ul li {
    width: 48%;
  }
}
@media only screen and (max-width: 480px) {
  .sec-1-cover .slide-cover .slides .carousel {
    overflow: hidden;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-content {
    padding-left: 30px;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-content h1 {
    font-size: 30px;
    padding: 0;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-content h2 {
    font-size: 16px;
    font-weight: normal;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-content a {
    font-size: 14px;
    font-weight: normal;
    padding: 5px 10px;
    top: 20px;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-img {
    float: right;
    max-width: 250px;
    right: 0;
    margin-top: 15%;
  }
  .sec-1-cover .slide-cover .slides .carousel .slide .slide-img img {
    max-width: 250px;
  }
  .sec-4-cover .sec-4 .service-list ul {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sec-4-cover .sec-4 .service-list ul li h2 {
    font-size: 14px;
  }

  .sec-4-cover .sec-4 .service-list ul li p {
    font-size: 12px;
  }

  .sec-2-cover h2 {
    padding-top: 30px;
  }
  .sec-2-cover p {
    font-size: 14px;
    padding: 10px 20px 30px 20px;
    text-align: justify;
  }
  .sec-3-cover .sec-3 h2 {
    padding-top: 30px;
    font-family: "Open Sans", sans-serif;
    font-size: 33px;
  }
  .sec-3-cover .sec-3 p {
    font-size: 14px;
    padding: 20px 20px 30px 20px;
    text-align: justify;
  }
  .sec-3-cover .sec-3 button {
    font-size: 12px;
  }
  /* About */
  .about {
    margin: 40px auto;
  }

  .about p {
    padding: 10px 25px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 25px;
    text-align: justify;
  }
}

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  padding: 70px;
}

@media only screen and (max-width: 767px) {
  .z-depth-2 {
    padding: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .z-depth-2 {
    padding: 60px;
  }
}

.product-head {
  padding-bottom: 20px;
}
table {
  text-align: left;
}

/* Details */
.details-root {
  margin: 120px auto 60px auto;
}
.details-background {
  background: url("/images/background.png"), #fff;
  animation: slide 20s infinite;
}

.quantity-product-details {
  display: flex;
}
.quantity-product-details button {
  border-radius: 0;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: black;
}

.quantity-product-details button:nth-child(1) {
  border-right: none;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.quantity-product-details button:nth-child(3) {
  border-right: none;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.quantity-product {
  display: flex;
  align-items: center;
}

.remove-item-button {
  width: auto !important;
  height: auto !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
  font-size: 14px;
  margin-left: 20px;
  border-right: 1px solid white !important;
}

.height-0 {
  max-height: 0px !important;
}

.cart-icon {
  float: right;
  margin-right: 10px;
  margin-top: 15px;
  cursor: pointer;
  position: relative;
}
.cart-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dc3545;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-size: 13px;
  position: absolute;
  top: -8px;
  right: -12px;
  font-weight: bolder;
}

@media only screen and (min-width: 975px) {
  .cart-icon {
    display: none;
  }
}
